<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="vacancy-setting-wrapper">
          <div class="accordion" id="vacancyAccordion">

            <div class="card">
              <div class="card-header" id="headingVacancyApplyEmailAddress">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseVacancyApplyEmailAddress" aria-expanded="false" aria-controls="collapseVacancyApplyEmailAddress">

                  <span class="text-capitalize vacancy-setting-title">Vacancy Apply Email Address</span>

                </button>
              </div>
              <div id="collapseVacancyApplyEmailAddress" class="collapse" aria-labelledby="headingVacancyApplyEmailAddress" data-parent="#vacancyAccordion">
                <div class="card-body">
                  <SettingVacancyApplyEmailAddress :vacancy-apply-email-address="this.vacancyApplyEmailAddress"/>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {
  mapActions,
  mapGetters
} from 'vuex';
import SettingVacancyApplyEmailAddress from "@/views/backEnd/settings/vacancies/includes/SettingVacancyApplyEmailAddress";

export default {
  name: "SettingVacancyList",
  components: {
    AppLayout,
    SettingVacancyApplyEmailAddress,

  },
  mixins: [ShowToastMessage, Loader],
  data() {
    return {
      getSettingsParams: {
        type: ["vacancy"],

        key: [
          "vacancy_apply_email_address",
        ],
      },
      currentState: {},
      frontEndErrors: [],

    }

  },

  computed: {
    ...mapGetters({
      vacancyApplyEmailAddress: "appSettings/settingVacancyApplyEmailAddress",


    }),

  },

  methods: {
    ...mapActions({

      getSettings: "appSettings/getSettings",
      putSetting: "appSettings/putSetting",

    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.vacancy-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.vacancy-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
